import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb55e1a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "center" }
const _hoisted_2 = { class: "my-collection-box" }
const _hoisted_3 = { class: "recently-visited" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "my-collection" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "need-collection-box" }
const _hoisted_9 = { class: "input-box" }
const _hoisted_10 = { class: "collection-menu-box" }
const _hoisted_11 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = ["onClick"]
const _hoisted_16 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_ctx.open)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "nav-operate",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleClose($event)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t('headNavigation.recentlyVisited')), 1),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recentlyVisitedMenus, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.path
                  }, [
                    _createElementVNode("span", {
                      onClick: _withModifiers(() => _ctx.handleLink((item as any).path), ["stop"])
                    }, _toDisplayString(_ctx.$i18n.locale === 'zh-CN' ? (item as any).menuTitle : (item as any).englishTitle), 9, _hoisted_4)
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t('headNavigation.myCollection')), 1),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collectionMenu, (item) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: item.menuId
                  }, [
                    _createElementVNode("span", {
                      onClick: _withModifiers(($event: any) => (_ctx.handleCollectionClick((item as any).path)), ["stop"])
                    }, _toDisplayString(_ctx.$i18n.locale === 'zh-CN' ? (item as any).menuTitle : (item as any).englishTitle), 9, _hoisted_6),
                    _createElementVNode("i", {
                      onClick: _withModifiers(($event: any) => (_ctx.handleDelCollection((item as any).menuId)), ["stop"])
                    }, [
                      _createVNode(_component_svg_icon, { "icon-class": "ic_yichu" })
                    ], 8, _hoisted_7)
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("input", {
                type: "text",
                placeholder: "请输入关键词",
                onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.handleInputChange && _ctx.handleInputChange(...args)), ["enter"]))
              }, null, 32),
              _createVNode(_component_svg_icon, { "icon-class": "ic_search" })
            ]),
            _createElementVNode("div", _hoisted_10, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formatMenu, (item) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: item.id
                }, [
                  (item.isShowModal)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("h1", null, [
                          (_ctx.$i18n.locale === 'zh-CN')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(item.meta.menuTitle), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(item.meta.englishTitle), 1))
                        ]),
                        _createElementVNode("ul", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (cItem) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: cItem.id
                            }, [
                              (!cItem.hidden && cItem.isShowModal)
                                ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                                    (_ctx.$i18n.locale === 'zh-CN')
                                      ? (_openBlock(), _createElementBlock("span", {
                                          key: 0,
                                          onClick: _withModifiers(($event: any) => (_ctx.handleClick(
                        (cItem.name === 'FactoryMonitoring' ? '' : item.path) + '/' + cItem.path)), ["stop"])
                                        }, _toDisplayString(cItem.meta.menuTitle), 9, _hoisted_15))
                                      : (_openBlock(), _createElementBlock("span", {
                                          key: 1,
                                          onClick: _withModifiers(($event: any) => (_ctx.handleClick(
                        (cItem.name === 'FactoryMonitoring' ? '' : item.path) + '/' + cItem.path)), ["stop"])
                                        }, _toDisplayString(cItem.meta.englishTitle), 9, _hoisted_16)),
                                    _createVNode(_component_svg_icon, {
                                      "icon-class": cItem.isCollect ? 'ic_yishoucang' : 'ic_shoucang_empty',
                                      onClick: _withModifiers(($event: any) => (_ctx.handleIsCollect(cItem.id)), ["stop"])
                                    }, null, 8, ["icon-class", "onClick"])
                                  ]))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}