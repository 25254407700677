import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, Transition as _Transition, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-443adcc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "drawer-content"
}
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "body" }
const _hoisted_5 = { class: "tabs-box" }
const _hoisted_6 = { class: "tabs" }
const _hoisted_7 = { class: "all-read" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = {
  key: 0,
  class: "notice-content"
}
const _hoisted_10 = { class: "notice-title" }
const _hoisted_11 = { class: "notice-desc" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = { class: "notice-content notice-emnpty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "drawer-container",
    onClick: _cache[5] || (_cache[5] = () => _ctx.$emit('update:visible', false))
  }, [
    _createVNode(_Transition, { name: "drawer" }, {
      default: _withCtx(() => [
        (_ctx.visible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_svg_icon, {
                    "icon-class": "ic_guan",
                    onClick: _cache[0] || (_cache[0] = _withModifiers(() => _ctx.$emit('update:visible', false), ["stop"]))
                  }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('headNavigation.notification')), 1)
                ]),
                _createElementVNode("div", {
                  class: "more",
                  onClick: _cache[1] || (_cache[1] = () => _ctx.$router.push({path: '/user-center/station-message'}))
                }, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('headNavigation.viewMore')), 1),
                  _createVNode(_component_svg_icon, { "icon-class": "ic_gengduo" })
                ])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.tabActive === 'all' ? 'active' : ''),
                        onClick: _cache[2] || (_cache[2] = _withModifiers(() => _ctx.handleQueryNotice('all'), ["stop"]))
                      }, _toDisplayString(_ctx.$t('headNavigation.all')), 3),
                      _createElementVNode("li", {
                        class: _normalizeClass(_ctx.tabActive === 'unread' ? 'active' : ''),
                        onClick: _cache[3] || (_cache[3] = _withModifiers(() => _ctx.handleQueryNotice('unread'), ["stop"]))
                      }, _toDisplayString(_ctx.$t('headNavigation.unread')), 3)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("button", {
                      onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleReadAll && _ctx.handleReadAll(...args)), ["stop"])),
                      disabled: _ctx.noticeList.length === 0
                    }, _toDisplayString(_ctx.$t('headNavigation.allRead')), 9, _hoisted_8)
                  ])
                ]),
                (_ctx.noticeList.length !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.noticeList, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item.id,
                          class: "notice-item"
                        }, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("span", {
                              class: _normalizeClass(item.status ? 'new' : '')
                            }, _toDisplayString(item.noticeTitle), 3),
                            _createElementVNode("span", null, _toDisplayString(item.createTime), 1)
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_a_tooltip, null, {
                              title: _withCtx(() => [
                                _createElementVNode("span", {
                                  innerHTML: item.noticeContent
                                }, null, 8, _hoisted_12)
                              ]),
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  innerHTML: item.noticeContent
                                }, null, 8, _hoisted_13)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_svg_icon, { "icon-class": "img_error_blank" }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('headNavigation.noMessage')), 1)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 512)), [
    [_vShow, _ctx.visible]
  ])
}