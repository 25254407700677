
import { defineComponent, ref, computed, onMounted } from 'vue'
import { Modal, message } from 'ant-design-vue'
import { permissionStore } from '@/store/permission'
import touxiang from '@/assets/img/ic_touxiang.png'
import { logOutApi } from '@/api/login'
import { getToken, removeToken } from '@/utils/token'
import { useRouter, useRoute } from 'vue-router'
import NavOperate from './NavOperate.vue'
import NoticeDrawer from '../notice-drawer/NoticeDrawer.vue'
import {
  queryRecentlyVisitedMenusApi,
  queryCollectionMenusApi,
  delCollectionMenuApi,
  collectionMenuApi
} from '@/api/nav'
import { noticeReadAllApi } from '@/api/notice'
import { getStoredLanguage, saveStoredLanguage } from '@/utils/languageStorage'
import { queryCanRegisterApi } from '@/api/register'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    NavOperate,
    NoticeDrawer
  },
  setup () {
    const permission = permissionStore()
    const router = useRouter()
    const route = useRoute()
    const visible = ref<boolean>(false)

    const { t } = useI18n()

    const handleShowMenu = ():void => {
      queryRecentlyVisitedMenus()
      queryCollectionMenus()
      visible.value = true
    }

    const handlePortal = ():void => {
      router.push('/portal')
    }

    const hideBtn = ref<boolean>(false)
    const getRegisterBtn = () => {
      queryCanRegisterApi().then((res) => {
        hideBtn.value = res.data
      })
    }

    const isLogin = computed<boolean>(() => {
      return getToken() !== null
    })

    const handleDel = (id: string) => {
      delCollectionMenuApi(id).then(() => {
        message.success(t('headNavigation.delSuccess'), 0.5).then(() => {
          queryCollectionMenus()
        })
      })
    }

    const handleCopy = () => {
      const input = document.createElement('input')
      input.value = userId.value
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      message.success('已复制')
    }

    const handleCollection = (id: string) => {
      collectionMenuApi(id).then(() => {
        message.success(t('headNavigation.collectionSuccess'), 0.5).then(() => {
          queryCollectionMenus()
        })
      })
    }

    // 用户头像
    const userProfilePicture = computed<string>(() => {
      return permission.$state.accountInfo.portrait ? `/api/user${permission.$state.accountInfo.portrait}` : touxiang
    })

    // 用户类型
    const userType = computed<string>(() => {
      let type = ''
      if (permission.$state.accountInfo.userType === 1) {
        type = permission.$state.accountInfo.gradeName
      } else {
        type = '机主用户'
      }
      return type
    })

    // 用户ID
    const userId = computed<string>(() => {
      return permission.$state.accountInfo.userId
    })

    // 用户名
    const userName = computed<string>(() => {
      return permission.$state.accountInfo.account
    })

    // 选中导航项
    const activeClass = computed<string>(() => {
      return route.path === '/ai-center/ControlConsole' ? 'active' : ''
    })

    // 退出
    const handleLogout = ():void => {
      Modal.confirm({
        title: t('del.title'),
        content: t('headNavigation.logOutTips'),
        centered: true,
        onOk: () => {
          logOutApi().then(() => {
            removeToken()
            router.replace({
              path: '/login'
            })
          })
        }
      })
    }

    // 查询最近访问菜单
    const recentlyVisitedMenus = ref<any []>([])
    const queryRecentlyVisitedMenus = ():void => {
      queryRecentlyVisitedMenusApi().then(res => {
        recentlyVisitedMenus.value = res.data
      })
    }

    // 查询我的收藏菜单
    const collectionMenu = ref([])
    const queryCollectionMenus = ():void => {
      queryCollectionMenusApi().then(res => {
        collectionMenu.value = res.data ? res.data : []
      })
    }

    // 菜单格式化
    const searchMenuCondition = ref<string>('')
    const formatMenu = computed(() => {
      const collectionId: string[] = []
      collectionMenu.value.forEach(item => {
        collectionId.push((item as any).menuId)
      })

      if (searchMenuCondition.value === '') {
        permission.$state.menuList.forEach(element => {
          element.children.forEach((item: { id: string; isCollect: boolean, [propName: string]: any }) => {
            if (collectionId.includes(item.id)) {
              item.isCollect = true
            } else {
              item.isCollect = false
            }
          })
        })
        return permission.$state.menuList
      } else {
        permission.$state.filterMenuList.forEach(element => {
          element.children.forEach((item: { id: string; isCollect: boolean, [propName: string]: any }) => {
            if (collectionId.includes(item.id)) {
              item.isCollect = true
            } else {
              item.isCollect = false
            }
          })
        })
        return permission.$state.filterMenuList
      }
    })

    const handleFilterCondition = (value: string) => {
      permission.filterMenus(value)
      searchMenuCondition.value = value
    }

    // 中英文切换
    const handleChangeLanguage = () => {
      if (getStoredLanguage() === 'en-US') {
        saveStoredLanguage('zh-CN')
      } else {
        saveStoredLanguage('en-US')
      }
      window.location.reload()
    }
    const languageType = computed(() => {
      if (getStoredLanguage() === 'zh-CN') {
        return 'EN'
      } else if (getStoredLanguage() === 'en-US') {
        return '中'
      } else {
        return 'EN'
      }
    })

    // websocket查询通知
    const wsRef = ref()
    const noticeContent = ref<any[]>([])
    const noticeVisible = ref<boolean>(false)
    const ip = window.location.host
    const protocol = window.location.protocol
    const noticeList = ref<any[]>([])
    const initWebsocket = () => {
      let wsPrefix = ''
      if (protocol === 'http:') {
        wsPrefix = 'ws'
      } else {
        wsPrefix = 'wss'
      }
      const ws = new WebSocket(`${wsPrefix}://${ip}/ws/user/websocket/notify/${permission.$state.accountInfo.userId}`)
      wsRef.value = ws
      ws.onmessage = (e) => {
        noticeContent.value = JSON.parse(e.data)
        noticeList.value = JSON.parse(e.data)
      }
    }

    const handleTabNotice = (type: string) => {
      if (type === 'all') {
        noticeList.value = noticeContent.value
      } else {
        noticeList.value = noticeContent.value.filter(item => item.status === 1)
      }
    }

    const handleReadAll = () => {
      noticeReadAllApi().then(() => {
        message.success(t('headNavigation.allRead'), 0.5).then(() => {
          noticeList.value.forEach(e => {
            e.status = 0
          })
        })
      })
    }

    const getNoticeVisible = () => {
      noticeVisible.value = true
    }

    onMounted(() => {
      getRegisterBtn()
      if (permission.$state.accountInfo.userType === 1) {
        initWebsocket()
      }
    })

    return {
      handleShowMenu,
      visible,
      handlePortal,
      userProfilePicture,
      userType,
      handleLogout,
      userId,
      userName,
      activeClass,
      recentlyVisitedMenus,
      collectionMenu,
      formatMenu,
      handleDel,
      handleCollection,
      handleChangeLanguage,
      languageType,
      isLogin,
      hideBtn,
      handleCopy,
      noticeVisible,
      noticeList,
      handleTabNotice,
      handleReadAll,
      noticeContent,
      getNoticeVisible,
      searchMenuCondition,
      handleFilterCondition,
      permission
    }
  }
})
