
import { defineComponent, ref, PropType } from 'vue'
import { Modal } from 'ant-design-vue'

export default defineComponent({
  emits: ['update:visible', 'tab', 'readAll'],
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false
    },
    noticeList: {
      type: Array as PropType<any[]>,
      required: true,
      default: () => []
    }
  },
  setup (_, { emit }) {
    const tabActive = ref('all')

    const handleQueryNotice = (type: string) => {
      tabActive.value = type
      emit('tab', type)
    }

    const handleReadAll = () => {
      Modal.confirm({
        title: '确认标记所有已读',
        content: '确认标记所有未读为已读吗',
        centered: true,
        onOk: () => {
          emit('readAll')
        }
      })
    }

    return {
      tabActive,
      handleQueryNotice,
      handleReadAll
    }
  }
})
