import http from '@/utils/http'

const noticeReadAllApi = () => {
  return http({
    method: 'post',
    url: '/user/notice/readAll'
  })
}

export {
  noticeReadAllApi
}
