
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import type { PropType } from 'vue'
interface MenuItemFace {
  isShowModal: boolean
  [propName: string]: any
}

export default defineComponent({
  emits: ['update:open', 'del', 'collection', 'fileterMenu'],
  props: {
    open: {
      type: Boolean,
      required: true
    },
    recentlyVisitedMenus: {
      type: Array,
      default: () => []
    },
    collectionMenu: {
      type: Array,
      default: () => []
    },
    formatMenu: {
      type: Array as PropType<MenuItemFace[]>,
      default: (): {isShowModal: boolean
      [propName: string]: any}[] => []
    },
    condition: {
      type: String,
      default: '',
      required: true
    }
  },
  setup (_, { emit }) {
    const searchMenuCondition = ref<string>('')
    const router = useRouter()
    const handleLink = (path: string):void => {
      router.push({
        path: path
      })
      emit('update:open', false)
    }
    const handleCollectionClick = (path: string):void => {
      router.push({
        path: path
      })
      emit('update:open', false)
    }
    const handleDelCollection = (id: string):void => {
      emit('del', id)
    }
    const handleInputChange = (e: any):void => {
      emit('fileterMenu', e.target.value)
    }
    const handleClick = (path: string):void => {
      router.push({
        path: path
      })
      emit('update:open', false)
    }
    const handleIsCollect = (id: string) => {
      emit('collection', id)
    }
    const handleClose = (e: any):void => {
      if (e.target.getAttribute('class') === 'nav-operate') {
        emit('update:open', false)
      }
    }
    return {
      handleLink,
      handleCollectionClick,
      handleDelCollection,
      handleInputChange,
      handleClick,
      handleIsCollect,
      handleClose,
      searchMenuCondition
    }
  }
})
