import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eb690a2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "control-head-navigation" }
const _hoisted_2 = { class: "logo" }
const _hoisted_3 = { class: "tools-box" }
const _hoisted_4 = { class: "out-ul" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  href: "https://docs.aiserver.cn/SitonCloud/introduction/",
  target: "_blank"
}
const _hoisted_7 = { class: "out-ul" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { style: {"margin-left":"0px"} }
const _hoisted_11 = {
  key: 3,
  class: "console"
}
const _hoisted_12 = {
  key: 4,
  class: "user-li"
}
const _hoisted_13 = { class: "user-profile" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { style: {"margin-left":"6px"} }
const _hoisted_16 = { class: "user-content" }
const _hoisted_17 = { class: "content" }
const _hoisted_18 = { class: "name-type" }
const _hoisted_19 = ["src"]
const _hoisted_20 = { style: {"margin-left":"12px"} }
const _hoisted_21 = { class: "user-type common-class" }
const _hoisted_22 = {
  key: 0,
  class: "account-version"
}
const _hoisted_23 = { class: "help-out" }
const _hoisted_24 = {
  href: "https://docs.aiserver.cn/SitonCloud/introduction/",
  target: "_blank"
}
const _hoisted_25 = {
  key: 5,
  class: "login-register"
}
const _hoisted_26 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_nav_operate = _resolveComponent("nav-operate")!
  const _component_notice_drawer = _resolveComponent("notice-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "menu-logo",
        style: _normalizeStyle(!_ctx.isLogin ? { justifyContent: 'center' }: { justifyContent: 'flex-start' })
      }, [
        (_ctx.isLogin)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "menu-box",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleShowMenu && _ctx.handleShowMenu(...args)))
            }, [
              _createElementVNode("i", null, [
                _createVNode(_component_svg_icon, {
                  "icon-class": _ctx.visible ? 'ic_close' : 'ic_menu'
                }, null, 8, ["icon-class"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("i", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handlePortal && _ctx.handlePortal(...args)))
          }, [
            _createVNode(_component_svg_icon, { "icon-class": "img_logo_cloud_white" })
          ])
        ])
      ], 4),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          (_ctx.isLogin)
            ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
                _createVNode(_component_router_link, { to: "/market" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('headNavigation.computationalPowerMarket')), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("li", null, [
            _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.$t('headNavigation.helpCenter')), 1)
          ])
        ]),
        _createElementVNode("ul", _hoisted_7, [
          (_ctx.isLogin)
            ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
                _createVNode(_component_router_link, { to: "/cost-center/order-management" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('headNavigation.cost')), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.isLogin)
            ? (_openBlock(), _createElementBlock("li", _hoisted_9, [
                _createVNode(_component_router_link, { to: "/user-center/work-order-management" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('headNavigation.laborOrder')), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_ctx.isLogin)
            ? (_openBlock(), _createElementBlock("li", {
                key: 2,
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.getNoticeVisible && _ctx.getNoticeVisible(...args)))
              }, [
                _createElementVNode("span", _hoisted_10, [
                  _createVNode(_component_a_badge, {
                    count: _ctx.noticeContent.length,
                    "show-zero": false
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_svg_icon, {
                        "icon-class": "ic_news",
                        class: "news"
                      })
                    ]),
                    _: 1
                  }, 8, ["count"])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("li", null, [
            _createElementVNode("span", {
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleChangeLanguage && _ctx.handleChangeLanguage(...args)))
            }, _toDisplayString(_ctx.languageType), 1)
          ]),
          (_ctx.isLogin)
            ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.activeClass),
                  onClick: _cache[4] || (_cache[4] = () => _ctx.$router.push('/ai-center/ControlConsole'))
                }, [
                  _createVNode(_component_svg_icon, { "icon-class": "ic_kongzhitai" }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('headNavigation.console')), 1)
                ], 2)
              ]))
            : _createCommentVNode("", true),
          (_ctx.isLogin)
            ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("img", {
                    src: _ctx.userProfilePicture,
                    alt: "",
                    style: {"width":"30px","height":"30px","border-radius":"50%"}
                  }, null, 8, _hoisted_14),
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.userName), 1)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("ul", _hoisted_18, [
                      _createElementVNode("li", null, [
                        _createElementVNode("img", {
                          src: _ctx.userProfilePicture,
                          alt: "",
                          style: {"width":"36px","height":"36px","border-radius":"50%"}
                        }, null, 8, _hoisted_19),
                        _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.userName), 1)
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.userType), 1)
                      ])
                    ]),
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", null, " ID:" + _toDisplayString(_ctx.userId), 1),
                      _createElementVNode("li", null, [
                        _createElementVNode("span", {
                          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleCopy && _ctx.handleCopy(...args)))
                        }, [
                          _createVNode(_component_svg_icon, { "icon-class": "ic_fuzhi" })
                        ])
                      ])
                    ]),
                    (_ctx.permission.$state.accountInfo.isMaster)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                          _createElementVNode("ul", null, [
                            _createElementVNode("li", {
                              onClick: _cache[6] || (_cache[6] = () => _ctx.$router.push('/user-center/basic-settings'))
                            }, [
                              _createVNode(_component_svg_icon, { "icon-class": "ic_zhanghao" }),
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('headNavigation.basicSettings')), 1)
                            ]),
                            _createElementVNode("li", {
                              onClick: _cache[7] || (_cache[7] = () => _ctx.$router.push('/user-center/security-setting'))
                            }, [
                              _createVNode(_component_svg_icon, { "icon-class": "ic_anquan" }),
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('headNavigation.securitySetting')), 1)
                            ])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("ul", _hoisted_23, [
                      _createElementVNode("li", null, [
                        _createElementVNode("span", null, [
                          _createElementVNode("a", _hoisted_24, _toDisplayString(_ctx.$t('headNavigation.helpCenter')), 1)
                        ])
                      ]),
                      _createElementVNode("li", null, [
                        _createElementVNode("span", {
                          onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.handleLogout && _ctx.handleLogout(...args)))
                        }, _toDisplayString(_ctx.$t('headNavigation.logOut')), 1)
                      ])
                    ])
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("li", _hoisted_25, [
                _createElementVNode("span", {
                  onClick: _cache[9] || (_cache[9] = () => _ctx.$router.push('/login'))
                }, _toDisplayString(_ctx.$t('headNavigation.login')), 1),
                (_ctx.hideBtn)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                      _createVNode(_component_a_divider, { type: "vertical" }),
                      _createElementVNode("button", {
                        class: "register-btn",
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$router.push('/register')))
                      }, _toDisplayString(_ctx.$t('headNavigation.register')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
        ])
      ])
    ]),
    _createVNode(_component_nav_operate, {
      open: _ctx.visible,
      "onUpdate:open": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.visible) = $event)),
      "recently-visited-menus": _ctx.recentlyVisitedMenus,
      "collection-menu": _ctx.collectionMenu,
      "format-menu": _ctx.formatMenu,
      condition: _ctx.searchMenuCondition,
      onDel: _ctx.handleDel,
      onCollection: _ctx.handleCollection,
      onFileterMenu: _ctx.handleFilterCondition
    }, null, 8, ["open", "recently-visited-menus", "collection-menu", "format-menu", "condition", "onDel", "onCollection", "onFileterMenu"]),
    _createVNode(_component_notice_drawer, {
      visible: _ctx.noticeVisible,
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.noticeVisible) = $event)),
      "notice-list": _ctx.noticeList,
      onTab: _ctx.handleTabNotice,
      onReadAll: _ctx.handleReadAll
    }, null, 8, ["visible", "notice-list", "onTab", "onReadAll"])
  ], 64))
}